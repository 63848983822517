import { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { fetchUserData } from "./usrinfo";

const useAccess = () => {
    const [access, setAccess] = useState(null);

    useEffect(() => {
        const callAccess = async () => {
            let res = await pageAccess();
            setAccess(res);
        };
        callAccess();
    }, []);

    const pageAccess = async () => {
        try {
            let pdata = JSON.parse(localStorage.getItem('userDetails'));
            let path = window.location.pathname;
            path = path.split("/");
            path = path[path.length - 1];

            let config = await fetchUserData();
            //console.log(`${process.env.REACT_APP_API_HOST}admin/role/menu/permission/link/${pdata.roleid}/${path}`);
            const response = await axios.get(`${process.env.REACT_APP_API_HOST}admin/role/menu/permission/link/${pdata.roleid}/${path}`, config);
            return response.data.data;
        } catch (error) {
            console.error("Error fetching menu items:", error);
            return null;
        }
    };

    const fetchAccess = useCallback(() => {
        let a = {};
        let d = access?access[0].permissions.split(","):"";
          if(d.includes("C")){
            a.v=true;
            a.a=true;
          }
          if(d.includes("EO")){
            a.v=true;
            a.e=true;
          }
          if(d.includes("VO")){
            a.v=true;
          }
          if(d.includes("EDA")){
            a.e=true;
            a.d=true;
            a.v=true;
          }

          if(d.includes("VA")){
            a.v=true;
          }
        return a;
    }, [access]);

    return { fetchAccess };
};

export default useAccess;
