import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { editSkillAPI, editSkill } from "../../api/skill";
import swal from "sweetalert";
const initialState={
	skill_title: '',
	skill_type: '',
	published: ''
}

const EditSkill = (props) => {
	const { editModal, setEditModal, dataFetch, editid, setEditContactId } = props;
	const [editFormData, setEditFormData] = useState({})

	useEffect(() => {
		if(editid>0){
			updateFormData();
     	}
	}, [editid])

	const updateFormData = async () => {
		let formValues = await editSkillAPI(editid);
		setEditFormData({...formValues});
	}

	//update data function
	const handleEditFormChange = (event) => {
		event.preventDefault();
		const fieldName = event.target.getAttribute('name');
		const fieldValue = event.target.value;
		const newFormData = { ...editFormData };
		newFormData[fieldName] = fieldValue;
		setEditFormData(newFormData);
	};

	// edit form data submit
	const handleEditFormSubmit = async (event) => {
		event.preventDefault();
		var error = false;
		var errorMsg = '';
        if(editFormData.skill_title === ""){
            error = true;
			errorMsg = 'Please fill Skill Name';
        }

        if(!error){
			const editedContact = {
				skill_title: editFormData.skill_title,
				skill_type: editFormData.skill_type,
				status: editFormData.published
			}
			await editSkill(editedContact, editid);
			dataFetch(true);
			setEditContactId(null);
			setEditFormData(initialState);
			setEditModal(false);
			swal('Successfully Updated Skill!', 'Successfully Updated', "success");
	}else{
		swal('Oops', errorMsg, "error");
	}

	}
	return (
		<>
			<Modal className="modal fade bd-example-modal-lg" show={editModal} onHide={setEditModal} centered size="lg">
				<div role="document">
					<div className="">
						<form>
							<div className="modal-header">
								<h4 className="modal-title fs-20">Update Skill</h4>
								<button type="button" className="btn-close" onClick={() => setEditModal(false)} data-dismiss="modal">
									<span></span>
								</button>
							</div>
							<div className="modal-body">
								<i className="flaticon-cancel-12 close" data-dismiss="modal"></i>
								<div className="add-contact-box">
									<div className="add-contact-content">
										<div className="form-group mb-6">
											<div className="row">
												<div className="form-group mb-6 col-md-12 mb-3">
													<label className="text-black font-w500">Skill Name <span className="text-danger">*</span> </label>
													<div className="contact-name">
														<input
															type="text"
															className="form-control"
															autoComplete="off"
															name="skill_title"
															required="required"
															value={editFormData?.skill_title}
															onChange={handleEditFormChange}
															placeholder="Enter Skill Name"
														/>
														<span className="validation-text"></span>
													</div>
												</div>
											</div>
										</div>

										<div className="form-group mb-3">
											<label className="text-black font-w500">Skill Type</label>
											<div className="contact-name">
												<input
													className="form-check-input"
													type="radio"
													name="skill_type"
													value="1"
													id="skill_typeTech"
													checked={ editFormData?.skill_type == 1 }
													onChange={handleEditFormChange}
												/>
												<label className="form-check-label" htmlFor="statusActive">
													Tech &nbsp;&nbsp;&nbsp;
												</label>
												<input
													className="form-check-input"
													type="radio"
													name="skill_type"
													value="0"
													id="skill_typeNonTech"
													checked={ editFormData?.skill_type!=1 }
													onChange={handleEditFormChange}
												/>
												<label className="form-check-label" htmlFor="statusInactive">
													Non-Tech
												</label>
												<span className="validation-text"></span>
											</div>
										</div>

										<div className="form-group mb-3">
											<label className="text-black font-w500">Status</label>
											<div className="contact-name">
												<input
													className="form-check-input"
													type="radio"
													name="published"
													value="1"
													id="statusActive"
													checked={editFormData?.published == "1"}
													onChange={handleEditFormChange}
												/>
												<label className="form-check-label" htmlFor="statusActive">
													Active &nbsp;&nbsp;&nbsp;
												</label>
												<input
													className="form-check-input"
													type="radio"
													name="published"
													value="0"
													checked={editFormData?.published != "1"}
													id="statusInactive"
													onChange={handleEditFormChange}
												/>
												<label className="form-check-label" htmlFor="statusInactive">
													InActive
												</label>
												<span className="validation-text"></span>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="modal-footer">
								<button type="submit" className="btn btn-success" onClick={handleEditFormSubmit}>
									Update
								</button>
								<button type="button" onClick={() => setEditModal(false)} className="btn btn-danger">
									<i className="flaticon-delete-1"></i> Discard
								</button>
							</div>
						</form>
					</div>
				</div>
			</Modal>
		</>
	)
}
export default EditSkill;