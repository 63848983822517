import React, { useState, useEffect, useCallback } from "react";
import { Modal } from "react-bootstrap";
import { editEventAPI, editEvent } from "../../api/event";
import swal from "sweetalert";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { uploadEventImage } from "../../../services/CommonService";

const initialState = {
  title: "",
  facilitated_by: "",
  location: "",
  summary: "",
  trainer_details: "",
  start_date: "",
  start_time: "",
  end_date: "",
  end_time: "",
  register_defore: "",
  who_can_attend: "",
  ticket_price: "",
  event_link: "",
  event_know_popup_show: false,
  publish: "0",
  event_logo: "",
  status: "",
};

const EditEvent = (props) => {
  const { editModal, setEditModal, dataFetch, editid, setEditContactId } =
    props;
  const [editFormData, setEditFormData] = useState({});

  //   useCallback(() => {
  //     first;
  //   }, [second]);

  const updateFormData = useCallback(async () => {
    let formValues = await editEventAPI(editid);
    formValues.start_date = formValues.start_date.split("T")[0];
    formValues.end_date = formValues.end_date.split("T")[0];
    formValues.register_defore = formValues.register_defore.split("T")[0];
    formValues.publish = formValues.published;
    setEditFormData({ ...formValues });
  }, [editid]);

  useEffect(() => {
    if (editid > 0) {
      updateFormData();
    }
  }, [editid, updateFormData]);

  const handleEditFormChange = (event) => {
    const fieldName = event.target.getAttribute("name");
    const fieldValue =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    setEditFormData({ ...editFormData, [fieldName]: fieldValue });
  };

  const handleSummaryChange = (event, editor) => {
    const data = editor.getData();
    setEditFormData({ ...editFormData, summary: data });
  };

  const handleTrainerChange = (event, editor) => {
    const data = editor.getData();
    setEditFormData({ ...editFormData, trainer_details: data });
  };

  const handleEditFormSubmit = async (event) => {
    event.preventDefault();
    let error = false;
    let errorMsg = "";

    if (editFormData.title === "") {
      error = true;
      errorMsg = "Please fill Event Name";
    }

    if (!error) {
      editFormData.status = editFormData.publish;
      await editEvent(editFormData, editid);
      dataFetch();
      setEditContactId(null);
      setEditFormData(initialState);
      setEditModal(false);
      swal("Successfully Updated Event!", "Successfully Updated", "success");
    } else {
      swal("Oops", errorMsg, "error");
    }
  };

  const handleUploadImage = useCallback(
    (name, file) => {
      var filePath = file.name;
      var allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
      if (!allowedExtensions.exec(filePath)) {
        //error("Please upload file having extensions .jpeg/.jpg/.png only.");
        swal(
          "Oops",
          "Please upload file having extensions .jpeg/.jpg/.png only.",
          "error"
        );
        return false;
      }
      uploadEventImage(file)
        .then((res) => {
          if (res.data?.data) {
            setEditFormData({
              ...editFormData,
              [name]: res.data?.data,
            });
          } else {
            console.log(res.data?.error);
          }
          //setImageUploading({ uploding: false, name: null });
        })
        .catch((err) => {
          //setImageUploading({ uploding: false, name: null });
          console.log(err);
        });
    },
    [editFormData, setEditFormData]
  );

  return (
    <Modal
      className="modal fade bd-example-modal-lg"
      show={editModal}
      onHide={() => setEditModal(false)}
      centered
      size="lg"
    >
      <div role="document">
        <div className="">
          <form>
            <div className="modal-header">
              <h4 className="modal-title fs-20">Update Event</h4>
              <button
                type="button"
                className="btn-close"
                onClick={() => setEditModal(false)}
                data-dismiss="modal"
              >
                <span></span>
              </button>
            </div>
            <div className="modal-body">
              <i className="flaticon-cancel-12 close" data-dismiss="modal"></i>
              <div className="add-contact-box">
                <div className="add-contact-content">
                  <div className="row">
                    <div className="col-md-4 mb-3">
                      <div className="form-group">
                        <label className="text-black font-w500">
                          Event Title <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          autoComplete="off"
                          name="title"
                          required="required"
                          value={editFormData.title}
                          onChange={handleEditFormChange}
                          placeholder="Enter Event Title"
                        />
                      </div>
                    </div>
                    <div className="col-md-4 mb-3">
                      <div className="form-group">
                        <label className="text-black font-w500">
                          Facilitated By
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          autoComplete="off"
                          name="facilitated_by"
                          value={editFormData.facilitated_by}
                          onChange={handleEditFormChange}
                          placeholder="Enter Facilitator"
                        />
                      </div>
                    </div>
                    <div className="col-md-4 mb-3">
                      <div className="form-group">
                        <label className="text-black font-w500">Location</label>
                        <input
                          type="text"
                          className="form-control"
                          autoComplete="off"
                          name="location"
                          value={editFormData.location}
                          onChange={handleEditFormChange}
                          placeholder="Enter Location"
                        />
                      </div>
                    </div>

                    <div className="col-md-4 mb-3">
                      <div className="form-group">
                        <label className="text-black font-w500">
                          Event Image
                        </label>
                        <input
                          id="event_logo"
                          onChange={(e) => {
                            handleUploadImage("event_logo", e.target.files[0]);
                          }}
                          className="form-control"
                          type="file"
                          name="event_logo"
                          required=""
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12 mb-3">
                      <div className="form-group">
                        <label className="text-black font-w500">Summary</label>
                        <CKEditor
                          editor={ClassicEditor}
                          data={editFormData.summary || ""}
                          onChange={handleSummaryChange}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12 mb-3">
                      <div className="form-group">
                        <label className="text-black font-w500">
                          Trainer Details
                        </label>
                        <CKEditor
                          editor={ClassicEditor}
                          data={editFormData.trainer_details || ""}
                          onChange={handleTrainerChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4 mb-3">
                      <div className="form-group">
                        <label className="text-black font-w500">
                          Start Date
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          autoComplete="off"
                          name="start_date"
                          value={editFormData.start_date}
                          onChange={handleEditFormChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-4 mb-3">
                      <div className="form-group">
                        <label className="text-black font-w500">End Date</label>
                        <input
                          type="date"
                          className="form-control"
                          autoComplete="off"
                          name="end_date"
                          value={editFormData.end_date}
                          onChange={handleEditFormChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-4 mb-3">
                      <div className="form-group">
                        <label className="text-black font-w500">
                          Register Before
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          autoComplete="off"
                          name="register_defore"
                          value={editFormData.register_defore}
                          onChange={handleEditFormChange}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-4 mb-3">
                      <div className="form-group">
                        <label className="text-black font-w500">
                          Start Time
                        </label>
                        <input
                          type="time"
                          className="form-control"
                          autoComplete="off"
                          name="start_time"
                          value={editFormData.start_time}
                          onChange={handleEditFormChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-4 mb-3">
                      <div className="form-group">
                        <label className="text-black font-w500">End Time</label>
                        <input
                          type="time"
                          className="form-control"
                          autoComplete="off"
                          name="end_time"
                          value={editFormData.end_time}
                          onChange={handleEditFormChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-4 mb-3">
                      <div className="form-group">
                        <label className="text-black font-w500">
                          Who Can Attend
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          autoComplete="off"
                          name="who_can_attend"
                          value={editFormData.who_can_attend}
                          onChange={handleEditFormChange}
                          placeholder="Enter Eligibility"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-4 mb-3">
                      <div className="form-group">
                        <label className="text-black font-w500">
                          Ticket Price
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          autoComplete="off"
                          name="ticket_price"
                          value={editFormData.ticket_price}
                          onChange={handleEditFormChange}
                          placeholder="Enter Ticket Price"
                        />
                      </div>
                    </div>
                    <div className="col-md-4 mb-3">
                      <div className="form-group">
                        <label className="text-black font-w500">
                          Event Link
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          autoComplete="off"
                          name="event_link"
                          value={editFormData.event_link}
                          onChange={handleEditFormChange}
                          placeholder="Enter Event Link"
                        />
                      </div>
                    </div>
                    <div className="col-md-4 mb-3">
                      <div className="form-group">
                        <label className="text-black font-w500">
                          Show "How did you know about the event?" popup
                        </label>
                        <br />
                        <input
                          type="checkbox"
                          className="form-check-input"
                          name="event_know_popup_show"
                          checked={editFormData.event_know_popup_show}
                          onChange={handleEditFormChange}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-8">
                      <div className="form-group">
                        <label className="text-black font-w500">
                          Publish Status
                        </label>
                        <div>
                          <input
                            type="radio"
                            className="form-check-input"
                            name="publish"
                            value="1"
                            checked={editFormData.publish === "1"}
                            onChange={handleEditFormChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="publishActive"
                          >
                            Active
                          </label>
                          <input
                            type="radio"
                            className="form-check-input"
                            name="publish"
                            value="0"
                            checked={editFormData.publish === "0"}
                            onChange={handleEditFormChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="publishInactive"
                          >
                            Inactive
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="submit"
                className="btn btn-success"
                onClick={handleEditFormSubmit}
              >
                Update
              </button>
              <button
                type="button"
                onClick={() => setEditModal(false)}
                className="btn btn-danger"
              >
                <i className="flaticon-delete-1"></i> Discard
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default EditEvent;
