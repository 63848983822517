import axiosInstance from "./AxiosInstance";

export function getList(data) {
  return axiosInstance.post(
    "admin/candidate/job-fair-participants",
    data
  );
}

export function listStatus(data) {
  return axiosInstance.post("sub-recruiter/downloaded-resume", data);
}

// export function getTimeLog(data) {
//   return axiosInstance.post("sub-recruiter/login-logout-duration", data);
// }

// export function getProfileView(data) {
//   return axiosInstance.post("sub-recruiter/checkout-candidates", data);
// }
