import axios from "axios";
import { fetchUserData } from "./usrinfo";

export const planListAPI = async () => {
  try {
    let config = await fetchUserData();
    const response = await axios.get(
      `${process.env.REACT_APP_API_HOST}admin/plan`,
      config
    );
    return response.data.data;
  } catch (error) {
    console.error("Error fetching menu items:", error);
  }
};

export const categoryPlanListAPI = async () => {
   try {
     let config = await fetchUserData();
     const response = await axios.get(
       `${process.env.REACT_APP_API_HOST}admin/plan/categories-with-plans`,
       config
     );
     return response.data.data;
   } catch (error) {
     console.error("Error fetching menu items:", error);
   }
 };

export const editPlanAPI = async (id) => {
  try {
    let config = await fetchUserData();
    const response = await axios.get(
      `${process.env.REACT_APP_API_HOST}admin/plan/${id}`,
      config
    );
    return response.data.data;
  } catch (error) {
    console.error("Error fetching menu items:", error);
  }
};

export const addNewPlan = async (data) => {
  try {
    let config = await fetchUserData();
    await axios.post(
      `${process.env.REACT_APP_API_HOST}admin/plan`,
      data,
      config
    );
  } catch (error) {
    console.error("Error on inserting logs", error);
  }
};

export const editPlan = async (data, id) => {
  try {
    let config = await fetchUserData();
    await axios.put(
      `${process.env.REACT_APP_API_HOST}admin/plan/${id}`, // Ensure your URL is correct
      data,
      config
    );
  } catch (error) {
    console.error("Error on editing plan", error);
  }
};

export const deletePlan = async (id) => {
  try {
    let config = await fetchUserData();
    await axios.delete(
      `${process.env.REACT_APP_API_HOST}admin/plan/${id}`,
      config
    );
    console.log("Plan deleted successfully");
  } catch (error) {
    console.error("Error on deleting plan", error);
  }
};

export const getCategories = async () => {
  try {
    let config = await fetchUserData();
    const response = await axios.post(
      `${process.env.REACT_APP_API_HOST}admin/plan/categories`,
      {},
      config
    );
    return response.data.data;
    console.log("Category fetch successfully");
  } catch (error) {
    console.error("Error on category fetch", error);
  }
};
