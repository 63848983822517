import React, { useCallback, useState } from "react";
import { Modal } from "react-bootstrap";
import swal from "sweetalert";
import { addNewEvent } from "../../api/event";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { uploadEventImage } from "../../../services/CommonService";

const initialState = {
  title: "",
  facilitated_by: "",
  location: "",
  summary: "",
  trainer_details: "",
  start_date: "",
  start_time: "",
  end_date: "",
  end_time: "",
  register_defore: "",
  who_can_attend: "",
  ticket_price: "",
  event_link: "",
  event_know_popup_show: false,
  event_logo: "",
  status: "0",
};

const NewEvent = (props) => {
  const { addCard, setAddCard, dataFetch } = props;
  const [addFormData, setAddFormData] = useState(initialState);

  const handleAddFormChange = (event) => {
    const fieldName = event.target.getAttribute("name");
    const fieldValue =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    setAddFormData({ ...addFormData, [fieldName]: fieldValue });
  };

  const handleSummaryChange = (event, editor) => {
    const data = editor.getData();
    setAddFormData({ ...addFormData, summary: data });
  };

  const handleTrainerChange = (event, editor) => {
    const data = editor.getData();
    setAddFormData({ ...addFormData, trainer_details: data });
  };

  const handleAddFormSubmit = async (event) => {
    event.preventDefault();
    let error = false;
    let errorMsg = "";

    if (addFormData.title === "") {
      error = true;
      errorMsg = "Please fill Event Title";
    }

    if (!error) {
      await addNewEvent(addFormData);
      dataFetch();
      setAddCard(false);
      setAddFormData(initialState);
      swal("Successfully Added Event!", "Successfully Added", "success");
    } else {
      swal("Oops", errorMsg, "error");
    }
  };

  const handleUploadImage = useCallback(
    (name, file) => {
      //setImageUploading({ uploding: true, name: name });
      var filePath = file.name;
      var allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
      if (!allowedExtensions.exec(filePath)) {
        //error("Please upload file having extensions .jpeg/.jpg/.png only.");
        swal(
          "Oops",
          "Please upload file having extensions .jpeg/.jpg/.png only.",
          "error"
        );
        return false;
      }
      uploadEventImage(file)
        .then((res) => {
          if (res.data?.data) {
            setAddFormData({
              ...addFormData,
              [name]: res.data?.data,
            });
          } else {
            console.log(res.data?.error);
          }
          //setImageUploading({ uploding: false, name: null });
        })
        .catch((err) => {
          //setImageUploading({ uploding: false, name: null });
          console.log(err);
        });
    },
    [addFormData, setAddFormData]
  );

  return (
    <Modal
      className="modal fade bd-example-modal-lg"
      show={addCard}
      onHide={() => setAddCard(false)}
      centered
      size="lg"
    >
      <div role="document">
        <div className="">
          <form>
            <div className="modal-header">
              <h4 className="modal-title fs-20">Add New Event</h4>
              <button
                type="button"
                className="btn-close"
                onClick={() => setAddCard(false)}
                data-dismiss="modal"
              >
                <span></span>
              </button>
            </div>
            <div className="modal-body">
              <i className="flaticon-cancel-12 close" data-dismiss="modal"></i>
              <div className="add-contact-box">
                <div className="add-contact-content">
                  <div className="row">
                    <div className="col-md-4 mb-3">
                      <div className="form-group">
                        <label className="text-black font-w500">
                          Event Title <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          autoComplete="off"
                          name="title"
                          required="required"
                          onChange={handleAddFormChange}
                          placeholder="Enter Event Title"
                        />
                      </div>
                    </div>
                    <div className="col-md-4 mb-3">
                      <div className="form-group">
                        <label className="text-black font-w500">
                          Facilitated By
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          autoComplete="off"
                          name="facilitated_by"
                          onChange={handleAddFormChange}
                          placeholder="Enter Facilitator"
                        />
                      </div>
                    </div>
                    <div className="col-md-4 mb-3">
                      <div className="form-group">
                        <label className="text-black font-w500">Location</label>
                        <input
                          type="text"
                          className="form-control"
                          autoComplete="off"
                          name="location"
                          onChange={handleAddFormChange}
                          placeholder="Enter Location"
                        />
                      </div>
                    </div>

                    <div className="col-md-4 mb-3">
                      <div className="form-group">
                        <label className="text-black font-w500">
                          Event Image
                        </label>
                        <input
                          id="event_logo"
                          onChange={(e) => {
                            handleUploadImage("event_logo", e.target.files[0]);
                          }}
                          className="form-control"
                          type="file"
                          name="event_logo"
                          required=""
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12 mb-3">
                      <div className="form-group">
                        <label className="text-black font-w500">Summary</label>
                        <CKEditor
                          editor={ClassicEditor}
                          data={addFormData.summary}
                          onChange={handleSummaryChange}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12 mb-3">
                      <div className="form-group">
                        <label className="text-black font-w500">
                          Trainer Details
                        </label>
                        <CKEditor
                          editor={ClassicEditor}
                          data={addFormData.trainer_details}
                          onChange={handleTrainerChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4 mb-3">
                      <div className="form-group">
                        <label className="text-black font-w500">
                          Start Date
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          autoComplete="off"
                          name="start_date"
                          onChange={handleAddFormChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-4 mb-3">
                      <div className="form-group">
                        <label className="text-black font-w500">End Date</label>
                        <input
                          type="date"
                          className="form-control"
                          autoComplete="off"
                          name="end_date"
                          onChange={handleAddFormChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-4 mb-3">
                      <div className="form-group">
                        <label className="text-black font-w500">
                          Register Before
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          autoComplete="off"
                          name="register_defore"
                          onChange={handleAddFormChange}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-4 mb-3">
                      <div className="form-group">
                        <label className="text-black font-w500">
                          Start Time
                        </label>
                        <input
                          type="time"
                          className="form-control"
                          autoComplete="off"
                          name="start_time"
                          onChange={handleAddFormChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-4 mb-3">
                      <div className="form-group">
                        <label className="text-black font-w500">End Time</label>
                        <input
                          type="time"
                          className="form-control"
                          autoComplete="off"
                          name="end_time"
                          onChange={handleAddFormChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-4 mb-3">
                      <div className="form-group">
                        <label className="text-black font-w500">
                          Who Can Attend
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          autoComplete="off"
                          name="who_can_attend"
                          onChange={handleAddFormChange}
                          placeholder="Enter Eligibility"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-4 mb-3">
                      <div className="form-group">
                        <label className="text-black font-w500">
                          Ticket Price
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          autoComplete="off"
                          name="ticket_price"
                          onChange={handleAddFormChange}
                          placeholder="Enter Ticket Price"
                        />
                      </div>
                    </div>
                    <div className="col-md-4 mb-3">
                      <div className="form-group">
                        <label className="text-black font-w500">
                          Event Link
                        </label>
                        <input
                          type="url"
                          className="form-control"
                          autoComplete="off"
                          name="event_link"
                          onChange={handleAddFormChange}
                          placeholder="Enter Event Link"
                        />
                      </div>
                    </div>
                    <div className="col-md-4 mb-3">
                      <div className="form-group">
                        <label className="text-black font-w500">
                          Show "How did you know about the event?" popup &nbsp;
                        </label>
                        <br />
                        <input
                          type="checkbox"
                          className="form-check-input"
                          name="event_know_popup_show"
                          onChange={handleAddFormChange}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-8">
                      <div className="form-group">
                        <label className="text-black font-w500">
                          Publish Status{" "}
                        </label>
                        <div>
                          <input
                            type="radio"
                            className="form-check-input"
                            name="status"
                            value="1"
                            checked={addFormData.status === "1"}
                            onChange={handleAddFormChange}
                          />
                          <label
                            className="form-check-label mb"
                            htmlFor="publishActive"
                          >
                            Active &nbsp;&nbsp;&nbsp;
                          </label>
                          <input
                            type="radio"
                            className="form-check-input"
                            name="status"
                            value="0"
                            checked={addFormData.status !== "1"}
                            onChange={handleAddFormChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="publishInactive"
                          >
                            Inactive
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="submit"
                className="btn btn-success"
                onClick={handleAddFormSubmit}
              >
                Add
              </button>
              <button
                type="button"
                onClick={() => setAddCard(false)}
                className="btn btn-danger"
              >
                <i className="flaticon-delete-1"></i> Discard
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default NewEvent;
