import axiosInstance from "./AxiosInstance";

export function setSelectColumn(column) {
  let putData = { fields: column };
  return axiosInstance.put("editor/candidates/update/searchkeys", putData);
}

export function getSelectedColumn(column) {
   return axiosInstance.get("editor/candidates/fetch/searchkeys");
 }
