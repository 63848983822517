import axiosInstance from "./AxiosInstance";

export function addJob(data) {
  return axiosInstance.post("/recruiter/jobs/create", data);
}

export function jobOrder() {
  return axiosInstance.get("/admin/job/job-sorted-order");
}

export function updateJobOrder(data) {
  return axiosInstance.post("/admin/job/update-job-sort-order", data);
}

export function getJob(id) {
  return axiosInstance.get("/recruiter/jobs/" + id);
}

export function updateJob(id, data) {
  return axiosInstance.put("/recruiter/jobs/update/" + id, data);
}

export function updateJobStatus(data) {
  return axiosInstance.post("/recruiter/jobs/change-status", data);
}

export function getSkills() {
  return axiosInstance.get("/get/skills");
}

export function getLocations() {
  return axiosInstance.get("/get/cities");
}

export function getJobTypes() {
  //return axiosInstance.post('/get/skills');
}

export function updateCandidateJobStatus(data) {
  return axiosInstance.post("/recruiter/applicant/update-status", data);
}

export function getIdentity() {
  return axiosInstance.get("/get/identity");
}

export function getPublishedJob() {
  return axiosInstance.post("/recruiter/jobs/published-list");
}
