import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import { skillListAPI, deleteSkill } from "../../api/skill";
import NewSkill from "./NewSkill";
import EditSkill from "./EditSkill";
import useAccess from "../../api/useAccess";
import { debounce } from "lodash";
import DataTable from "react-data-table-component";
import SearchInputComponent from "../App/Common/SearchInputComponent";

const SkillLists = () => {
  const { fetchAccess } = useAccess();
  const permission = fetchAccess();
  const columns = useMemo(
    () => [
      {
        name: "S. No",
        selector: (row) => row.id,
        wrap: true,
        sortable: true,
        sortField: "id",
      },
      {
        name: "Skill",
        selector: (row) => row.skill_title,
        wrap: true,
        sortable: true,
        sortField: "skill_title",
      },
      {
        name: "Type",
        selector: (row) => (row.skill_type === 1 ? "Tech" : "Non-Tech"),
        sortable: true,
        sortField: "skill_type",
      },
      {
        name: "Status",
        selector: (row) => (
          <span
            className={`badge badge-lg light badge-${
              row.published === "1" ? "success" : "danger"
            }`}
          >
            {row.published === "1" ? "Active" : "Inactive"}
          </span>
        ),
        sortable: true,
        sortField: "published",
      },
      {
        name: "Action",
        button: "true",
        cell: (row) => (
          <>
            {permission.e === true && (
              <Link to="#" onClick={(event) => handleEditClick(event, row)}>
                <button className="btn btn-warning btn-sm light me-2">
                  <i className="fa fa-edit"></i>
                </button>
              </Link>
            )}
            {permission.d === true && (
              //   <Link
              //     to={"#"}
              //     className="btn btn-danger light"
              //     onClick={() => handleDeleteClick(row.id)}
              //   >
              //     <svg
              //       xmlns="http://www.w3.org/2000/svg"
              //       width="24px"
              //       height="24px"
              //       viewBox="0 0 24 24"
              //       version="1.1"
              //       className="svg-main-icon"
              //     >
              //       <g
              //         stroke="none"
              //         strokeWidth="1"
              //         fill="none"
              //         fillRule="evenodd"
              //       >
              //         <rect x="0" y="0" width="24" height="24"></rect>
              //         <path
              //           d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
              //           fill="#000000"
              //           fillRule="nonzero"
              //         ></path>
              //         <path
              //           d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
              //           fill="#000000"
              //           opacity="0.3"
              //         ></path>
              //       </g>
              //     </svg>
              //   </Link>

              <Link to="#" onClick={() => handleDeleteClick(row.id)}>
                <button className="btn btn-danger btn-sm light me-2">
                  <i className="fa fa-trash"></i>
                </button>
              </Link>
            )}
          </>
        ),
      },
    ],
    [permission]
  );

  const [data, setData] = useState([]);
  //const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  // State management
  const [addCard, setAddCard] = useState(false);

//   const [Skills, setSkills] = useState([]);
//   const [page, setPage] = useState(1);
//   const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
//   const [filter, setFilter] = useState("");
  const [loading, setLoading] = useState(false);
  //const [hasMore, setHasMore] = useState(true); // Track if there's more data to load

  // States that were missing
  const [editModal, setEditModal] = useState(false);
  const [editContactId, setEditContactId] = useState(null);

  //const observer = useRef();

  const [reqParams, setReqParams] = useState({
    page: currentPage,
    limit: perPage,
    sort_by: "created_at",
    sort_direction: "DESC",
    keyword: "",
  });
  const [fetch, setFetch] = useState(true);

  const fetchUsers = useCallback(
    (params) => {
      setLoading(true);
      skillListAPI(params)
        .then((res) => {
          setData(res?.data);
          setTotalRows(res?.total);
          setLoading(false);
          setFetch(false);
        })
        .catch((err) => {
          swal("Error", err?.message, "error");
          setLoading(false);
          setFetch(false);
        });
    },
    [setFetch]
  );

  useEffect(() => {
    if (fetch) {
      fetchUsers(reqParams);
    }
  }, [fetch, reqParams, fetchUsers]);

  //   useEffect(() => {
  //     dataFetch();
  //   }, [page, sortConfig, filter]);

  //   const dataFetch = async () => {
  //     setLoading(true);
  //     const params = {
  //       page,
  //       limit: 10,
  //       sort_by: sortConfig.key,
  //       sort_direction: sortConfig.direction,
  //       keyword: filter,
  //     };

  //     const data = await skillListAPI(params);
  //     if (data.length < 10) {
  //       setHasMore(false); // No more data to load
  //     }
  //     setSkills((prevSkills) => (page === 1 ? data : [...prevSkills, ...data]));
  //     setLoading(false);
  //   };

  const handleDeleteClick = async (contactId) => {
    const willDelete = await swal({
      title: "Are you sure?",
      text: "Are you sure that you want to delete this record?",
      icon: "warning",
      dangerMode: true,
    });

    if (willDelete) {
      await deleteSkill(contactId);
      //dataFetch();
      setFetch(true);
      swal("Deleted!", "Record has been deleted!", "success");
    }
  };

  const handleEditClick = async (event, contact) => {
    event.preventDefault();
    setEditContactId(contact.id);
    setEditModal(true);
  };

//   const requestSort = (key) => {
//     let direction = "asc";
//     if (sortConfig.key === key && sortConfig.direction === "asc") {
//       direction = "desc";
//     }
//     setSortConfig({ key, direction });
//   };

//   const handleFilterChange = debounce((e) => {
//     setFilter(e.target.value);
//     setPage(1); // Reset to first page on filter change
//   }, 300);

//   const loadMore = () => {
//     setPage((prevPage) => prevPage + 1);
//   };

//   const renderSortingIcon = (key) => {
//     if (sortConfig.key === key) {
//       if (sortConfig.direction === "asc") {
//         return <span>▲</span>;
//       } else if (sortConfig.direction === "desc") {
//         return <span>▼</span>;
//       }
//     }
//     return <span>↕</span>;
//   };

  // IntersectionObserver callback
//   const handleObserver = (entities) => {
//     const target = entities[0];
//     if (target.isIntersecting && hasMore && !loading) {
//       loadMore();
//     }
//   };

//   useEffect(() => {
//     const options = {
//       root: null,
//       rootMargin: "20px",
//       threshold: 1.0,
//     };
//     const observerInstance = new IntersectionObserver(handleObserver, options);
//     if (observer.current) {
//       observerInstance.observe(observer.current);
//     }
//     return () => {
//       if (observer.current) {
//         observerInstance.unobserve(observer.current);
//       }
//     };
//   }, [hasMore, loading]);

  const handleSearchText = useCallback(
    (text) => {
      setReqParams((state) => {
        return { ...state, keyword: text };
      });
      setFetch(true);
    },
    [setReqParams, setFetch]
  );

  const handlePageChange = useCallback(
    (page) => {
      setCurrentPage(page);
      setReqParams({
        ...reqParams,
        page: page,
      });
      setFetch(true);
    },
    [setReqParams, reqParams, setFetch]
  );

  const handlePerRowsChange = useCallback(
    async (newPerPage, page) => {
      setPerPage(newPerPage);
      setReqParams({
        ...reqParams,
        limit: newPerPage,
      });
      setFetch(true);
    },
    [setReqParams, reqParams, setFetch]
  );

  const handleSort = useCallback(
    (column, sortDirection) => {
      if (column?.sortField !== undefined) {
        setReqParams({
          ...reqParams,
          sort_by: column.sortField,
          sort_direction: sortDirection,
        });
        setFetch(true);
      }
    },
    [reqParams, setReqParams, setFetch]
  );

  return (
    <>
      <div className="d-flex align-items-center mb-4 flex-wrap">
        <h4 className="fs-20 font-w600  me-auto">Skill List</h4>
        <div className="d-flex align-items-center">
          {permission.a === true && (
            <Link
              to={"#"}
              className="btn btn-primary me-3 btn-sm"
              onClick={() => setAddCard(true)}
            >
              <i className="fas fa-plus me-2"></i>Add New Skill
            </Link>
          )}
          <NewSkill
            addCard={addCard}
            setAddCard={setAddCard}
            dataFetch={setFetch}
          />
          <EditSkill
            editModal={editModal}
            setEditModal={setEditModal}
            dataFetch={setFetch}
            editid={editContactId}
            setEditContactId={setEditContactId}
          />
        </div>
      </div>
      {/* <div className="row mb-4">
        <div className="col-md-6 ms-auto">
          <input
            type="text"
            placeholder="Filter by keyword"
            className="form-control me-3"
            onChange={handleFilterChange}
          />
        </div>
      </div> */}
      <div className="row">
        <div className="col-xl-12">
          <div className="table-responsive">
            <DataTable
              className="table display dataTablesCard job-table table-responsive-xl card-table dataTable no-footer"
              columns={columns}
              data={data}
              progressPending={loading}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              paginationDefaultPage={currentPage}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              subHeader
              subHeaderComponent={
                <SearchInputComponent setFilterText={handleSearchText} />
              }
              persistTableHead
              onSort={handleSort}
              sortServer
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SkillLists;
