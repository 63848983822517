import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import swal from "sweetalert";
import Select from "react-select";
import { addNewR } from "../../api/recruiter";
import { categoryPlanListAPI } from "../../api/plan";
import { roleMenuForRecruiterAPI } from "../../api/role";

const initialState = {
  plan_id: "",
  name: "",
  company_name: "",
  email: "",
  recruiter_type: "",
  menus: [],
  password: "",
  publish: "0",
  actively_hiring: 0,
};

const NewR = (props) => {
  const { addCard, setAddCard, dataFetch } = props;
  const [planlist, setPlanList] = useState([]);
  const [menulist, setMenuList] = useState([]);
  const [recruiterType, setRecruiterType] = useState([
    { value: 1, label: "Regular Recruiter" },
    { value: 2, label: "Internship Recruiter" },
  ]);
  const [addFormData, setAddFormData] = useState(initialState);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    let plan = await categoryPlanListAPI();
    setPlanList(plan);
    let mdata = await roleMenuForRecruiterAPI();
    setMenuList(mdata);
  };

  //console.log(planlist);

  const handleAddFormChange = (event) => {
    const fieldName = event.target.getAttribute("name");
    const fieldValue =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    setAddFormData({ ...addFormData, [fieldName]: fieldValue });
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    const menuId = parseInt(name, 10); // Assuming the name attribute is the menu ID
    let updatedMenus = [...addFormData.menus];
    if (checked) {
      updatedMenus.push(menuId);
    } else {
      updatedMenus = updatedMenus.filter((menu) => menu !== menuId);
    }
    setAddFormData({ ...addFormData, menus: updatedMenus });
  };

  const handleRecruiterTypeChange = (selectedOptions) => {
    const selectedValues = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setAddFormData({ ...addFormData, recruiter_type: selectedValues });
  };

  const handleAddFormSubmit = async (event) => {
    event.preventDefault();
    let error = false;
    let errorMsg = "";

    if (
      !addFormData.name ||
      !addFormData.company_name ||
      !addFormData.email ||
      !addFormData.recruiter_type.length ||
      !addFormData.plan_id ||
      !addFormData.password ||
      addFormData.menus.length === 0
    ) {
      error = true;
      errorMsg = "Please fill in all required fields";
    }

    if (!error) {
      addFormData.recruiter_type = addFormData?.recruiter_type?.join(",");
      addFormData.status = addFormData.publish;
      let res = await addNewR(addFormData);
      if (res != false) {
        if (res?.error) {
          swal("Oops", res?.error, "error");
        } else {
          dataFetch();
          setAddCard(false);
          setAddFormData(initialState);
          swal("Successfully Added!", "Successfully Added", "success");
        }
      } else {
        swal(
          "Oops",
          "Some problem occurs. Please contact administrator.",
          "error"
        );
      }
    } else {
      swal("Oops", errorMsg, "error");
    }
  };

  const isFormValid = () => {
    return (
      addFormData.name &&
      addFormData.company_name &&
      addFormData.email &&
      addFormData.recruiter_type.length > 0 &&
      addFormData.plan_id &&
      addFormData.password &&
      addFormData.menus.length > 0
    );
  };

  return (
    <Modal
      className="modal fade bd-example-modal-lg"
      show={addCard}
      onHide={() => setAddCard(false)}
      centered
      size="lg"
    >
      <div role="document">
        <div className="">
          <form>
            <div className="modal-header">
              <h4 className="modal-title fs-20">Add New Recruiter</h4>
              <button
                type="button"
                className="btn-close"
                onClick={() => setAddCard(false)}
                data-dismiss="modal"
              >
                <span></span>
              </button>
            </div>
            <div className="modal-body">
              <i className="flaticon-cancel-12 close" data-dismiss="modal"></i>
              <div className="add-contact-box">
                <div className="add-contact-content">
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <div className="form-group">
                        <label className="text-black font-w500">Name</label>
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          value={addFormData.name}
                          onChange={handleAddFormChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <div className="form-group">
                        <label className="text-black font-w500">
                          Company Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="company_name"
                          value={addFormData.company_name}
                          onChange={handleAddFormChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <div className="form-group">
                        <label className="text-black font-w500">Email</label>
                        <input
                          type="email"
                          className="form-control"
                          name="email"
                          value={addFormData.email}
                          onChange={handleAddFormChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <div className="form-group">
                        <label className="text-black font-w500">Password</label>
                        <input
                          type="password"
                          className="form-control"
                          name="password"
                          value={addFormData.password}
                          onChange={handleAddFormChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <div className="form-group">
                        <label className="text-black font-w500">
                          Recruiter Type
                        </label>
                        <Select
                          isMulti
                          options={recruiterType}
                          value={recruiterType.filter((type) =>
                            addFormData.recruiter_type.includes(type.value)
                          )}
                          onChange={handleRecruiterTypeChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <div className="form-group">
                        <label className="text-black font-w500">Plan ID</label>
                        <select
                          className="form-select"
                          name="plan_id"
                          value={addFormData.plan_id}
                          onChange={handleAddFormChange}
                        >
                          <option value="">Select Plan</option>
                          {planlist.map((plan, i) => (
                            <optgroup key={i} label={plan.name}>
                              {plan.plans.map((pln) => (
                                <option key={pln.plan_id} value={pln.plan_id}>
                                  {pln.plan_name}
                                </option>
                              ))}
                            </optgroup>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <div className="form-group">
                        <label className="text-black font-w500">
                          Actively hiring
                        </label>
                        <select
                          className="form-select"
                          name="actively_hiring"
                          //value={addFormData.actively_hiring}
                          onChange={handleAddFormChange}
                        >
                          <option value="">Select </option>
                          <option value={1}>Yes</option>
                          <option value={0}>No</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label className="text-black font-w500">Menus</label>
                        <div className="form-check">
                          {menulist.map((menu) => (
                            <div key={menu.id} className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name={menu.id.toString()} // Use ID as name attribute
                                checked={addFormData.menus.includes(menu.id)}
                                onChange={handleCheckboxChange}
                              />
                              <label className="form-check-label">
                                {menu.menu_name}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <div className="form-group">
                        <label className="text-black font-w500">
                          Publish Status
                        </label>
                        <div>
                          <input
                            type="radio"
                            className="form-check-input"
                            name="publish"
                            value="1"
                            checked={addFormData.publish == "1"}
                            onChange={handleAddFormChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="publishActive"
                          >
                            Active &nbsp;&nbsp;&nbsp;
                          </label>
                          <input
                            type="radio"
                            className="form-check-input"
                            name="publish"
                            value="0"
                            checked={addFormData.publish != "1"}
                            onChange={handleAddFormChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="publishInactive"
                          >
                            Inactive
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="submit"
                className="btn btn-success"
                onClick={handleAddFormSubmit}
                disabled={!isFormValid()}
              >
                Add
              </button>
              <button
                type="button"
                onClick={() => setAddCard(false)}
                className="btn btn-danger"
              >
                <i className="flaticon-delete-1"></i> Discard
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default NewR;
