import React, { useState, useEffect, useCallback } from "react";
import { object, string } from "yup";
import swal from "sweetalert";
import { jobOrder, updateJobOrder } from "../../../services/JobsService";
import MultiSelect from "./Jobs/MultiSelect";

let jobsOrder = object().shape({
  orderedIds: string().required({
    orderedIds: "Please select jobs.",
  }),
});

function AdminJobSort() {
  const [jobs, setJobs] = useState([]);
  const [errors, setError] = useState({});
  const [inputData, setInputData] = useState({
    orderedIds: "",
  });

  const formatOptionList = useCallback((res, label, value, callback) => {
    if (res.length > 0) {
      let optionArray = [];
      res.forEach((obj, i) => {
        optionArray.push({ value: obj[value], label: obj[label] });
      });
      callback(optionArray);
    }
  }, []);

  const fetchJobs = useCallback(() => {
    jobOrder()
      .then((res) => {
        formatOptionList(res?.data, "job_title", "id", setJobs);
      })
      .catch((err) => console.log(err));
  }, [setJobs, formatOptionList]);

  useEffect(() => {
    fetchJobs();
  }, [fetchJobs]);

  const handleMultiSelect = useCallback(
    (values, inputname) => {
      if (values?.length > 0) {
        let ids = [];
        values.forEach((element, i) => {
          ids.push(element?.value);
        });
        setInputData({
          ...inputData,
          [inputname]: ids.toString(),
        });
      } else {
        setInputData({
          ...inputData,
          [inputname]: "",
        });
      }
    },
    [inputData, setInputData]
  );

  const handleAdd = useCallback(
    (e) => {
      e.preventDefault();
      jobsOrder
        .validate(inputData, { strict: false, abortEarly: false })
        .then((resp) => {
          setError({});
          updateJobOrder(resp)
            .then((response) => {
              // console.log(response?.data);
              swal("Great Job!", response?.data.message, "success");
              //   if (response?.data?.message === "success") {
              //     swal("Great Job!", response?.data.message, "success");
              //   } else {
              //     swal("Opps", resp?.data.message, "error");
              //   }
            })
            .catch((e) => console.log(e));
        })
        .catch((err) => {
          let errObj = {};
          err.errors.map((msg, i) => {
            let keys = Object.keys(msg);
            if (errObj[keys[0]] === undefined) {
              errObj = { ...errObj, [keys[0]]: msg[keys[0]] };
            }
            return null;
          });
          setError(errObj);
        });
    },
    [inputData]
  );

  return (
    <div className="card">
      <div className="card-body">
        <form onSubmit={handleAdd}>
          <div className="row">
            <div className="col-xl-6">
              <div className="mb-3 form-group">
                <label htmlFor="locations">
                  Select Jobs<span style={{ color: "red" }}>*</span>{" "}
                </label>
                <MultiSelect
                  create={false}
                  multiple={true}
                  options={jobs}
                  onValueSelect={handleMultiSelect}
                  name="orderedIds"
                />
                {errors?.orderedIds !== undefined && (
                  <div className="invalid-feedback d-block">
                    {errors?.orderedIds}
                  </div>
                )}
              </div>
            </div>
            <div className="col-xl-3">
              <div className="form-group">
                <br />
                <button type="submit" className="btn btn-success">
                  <i className="fa fa-save"></i> Submit
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AdminJobSort;
