import axiosInstance from "./AxiosInstance";

export function billingHistoryList(data) {
  return axiosInstance.post("recruiter/user/billing-details", data);
}

export function offerPopup(data) {
  return axiosInstance.post("recruiter/user/offer-popup", data);
}

export function getPlanCategory(data) {
  return axiosInstance.post("admin/plan/categories-list", data);
}

export function getPlanMetrix(data) {
  return axiosInstance.post("admin/plan/category", data);
}

export function getPlanReport(data) {
  return axiosInstance.post("admin/plan/recruiter-history-by-plan", data);
}
