import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import swal from "sweetalert";
import {
  getPlanCategory,
  getPlanMetrix,
} from "../../../services/BillingHistory";
import SearchInputComponent from "./Common/SearchInputComponent";

function PlanMetrix() {
  const columns = useMemo(
    () => [
      {
        name: "View Action",
        button: "true",
        cell: (row) => (
          <Link target="_blank" to={`/${row?.plan_id}/plan-report`}>
            <button className="btn btn-primary btn-sm light me-2">
              <i className="fa fa-eye"></i>
            </button>
          </Link>
        ),
      },
      {
        name: "Plan Name",
        selector: (row) => row.plan_name,
        wrap: true,
        sortable: true,
        sortField: "id",
      },
      {
        name: "Total recruiter",
        selector: (row) => row.total_recruiter,
      },
      {
        name: "Total unlock",
        selector: (row) => row.total_unlock,
      },
      {
        name: "Total jobs",
        selector: (row) => row.total_jobs,
      },
      {
        name: "Total applicants",
        selector: (row) => row.total_applicants,
      },
      {
        name: "Total offers",
        selector: (row) => row.total_offers,
      },
    ],
    []
  );

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [categories, setCategories] = useState([]);

  const [reqParams, setReqParams] = useState({
    page: currentPage,
    limit: perPage,
    sort_by: "created_at",
    sort_direction: "DESC",
    keyword: "",
    category_id: "",
  });
  const [fetch, setFetch] = useState(true);

  const fetchUsers = useCallback(
    (params) => {
      setLoading(true);
      getPlanMetrix(params)
        .then((res) => {
          setData(res?.data?.data);
          setTotalRows(res?.data?.total_count);
          setLoading(false);
          setFetch(false);
        })
        .catch((err) => {
          swal("Error", err?.message, "error");
          setLoading(false);
          setFetch(false);
        });
    },
    [setFetch]
  );

  const fetchCategories = useCallback(() => {
    getPlanCategory().then((res) => {
      setCategories(res?.data?.data);
    });
  }, [setCategories]);

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  useEffect(() => {
    if (fetch && reqParams?.category_id) {
      fetchUsers(reqParams);
    }
  }, [fetch, reqParams, fetchUsers]);

  const handleSearchText = useCallback(
    (text) => {
      setReqParams((state) => {
        return { ...state, keyword: text };
      });
      setFetch(true);
    },
    [setReqParams, setFetch]
  );

  const handlePageChange = useCallback(
    (page) => {
      setCurrentPage(page);
      setReqParams({
        ...reqParams,
        page: page,
      });
      setFetch(true);
    },
    [setReqParams, reqParams, setFetch]
  );

  const handlePerRowsChange = useCallback(
    async (newPerPage, page) => {
      setPerPage(newPerPage);
      setReqParams({
        ...reqParams,
        limit: newPerPage,
      });
      setFetch(true);
    },
    [setReqParams, reqParams, setFetch]
  );

  const handleExtraFilter = useCallback(
    (filter) => {
      setReqParams((state) => {
        return { ...state, ...filter };
      });
      setFetch(true);
    },
    [setFetch, setReqParams]
  );

  const handleSort = useCallback(
    (column, sortDirection) => {
      if (column?.sortField !== undefined) {
        setReqParams({
          ...reqParams,
          sort_by: column.sortField,
          sort_direction: sortDirection,
        });
        setFetch(true);
      }
    },
    [reqParams, setReqParams, setFetch]
  );

  return (
    <>
      <div className="d-flex align-items-center mb-4 flex-wrap">
        <h4 className="fs-20 font-w600  me-auto">{""}</h4>
        <div>
          <select
            id="min_experience"
            //onChange={(e) => setJobId(e.target.value)}
            onChange={(e) => handleExtraFilter({ category_id: e.target.value })}
            className="btn btn-warning btn-sm me-3"
            type="text"
            name="min_experience"
          >
            <option value="">Select Plan Category</option>
            {categories.length > 0 &&
              categories.map((job, i) => {
                return (
                  <option value={job?.category_id} key={i}>
                    {job?.category_name}
                  </option>
                );
              })}
          </select>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="table-responsive">
            <DataTable
              className="table display dataTablesCard job-table table-responsive-xl card-table dataTable no-footer"
              columns={columns}
              data={data}
              progressPending={loading}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              paginationDefaultPage={currentPage}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              subHeader
              subHeaderComponent={
                <SearchInputComponent setFilterText={handleSearchText} />
              }
              persistTableHead
              onSort={handleSort}
              sortServer
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default PlanMetrix;
