import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
//import axiosInstance from "../../../services/AxiosInstance";
import swal from "sweetalert";
import SearchInputComponent from "./Common/SearchInputComponent";
import {
  downloadResume,
  unlockProfileList,
} from "../../../services/UnlockProfileService";
import { downloadCSV } from "../../../helpers/dataTableHelper";
import { getPublishedJob } from "../../../services/JobsService";

export default function UnlockProfile() {
  const handleDownload = useCallback((candidate_id) => {
    downloadResume({ candidate_id: String(candidate_id) })
      .then((res) => {
        let { data } = res.data;
        window.open(
          data?.baseUrl + data?.resume_file,
          "_blank",
          "rel=noopener noreferrer"
        );
      })
      .catch((err) => console.log(err));
  }, []);

  const tableClm = useMemo(() => {
    return [
      {
        name: "View Action",
        button: "true",
        cell: (row) => (
          <>
            <Link target="_blank" to={`/${row?.id}/candidate-profile`}>
              <button className="btn btn-primary btn-sm light mr-2">
                <i className="fa fa-eye"></i>
              </button>
            </Link>
            <button
              onClick={() => handleDownload(row?.id)}
              className="ms-2 btn btn-primary btn-sm light mr-2"
            >
              <i className="fa fa-download"></i>
            </button>
          </>
        ),
      },
      {
        name: "ID",
        selector: (row) => row.id,
        sortable: true,
        sortField: "id",
      },
      {
        name: "Name",
        selector: (row) => row.preferred_name,
        wrap: true,
        minWidth: "200px",
        sortable: true,
        sortField: "preferred_name",
      },
      {
        name: "Email",
        selector: (row) => row.email,
        wrap: true,
        minWidth: "200px",
        sortable: true,
        sortField: "email",
      },
      {
        name: "Phone",
        selector: (row) => row.ph_country_code + " " + row.phone_number,
        wrap: true,
        minWidth: "200px",
      },
      {
        name: "Out at Work",
        selector: (row) => row.out_at_work,
        wrap: true,
        minWidth: "200px",
        sortable: true,
        sortField: "out_at_work",
      },
      {
        name: "Identify As",
        selector: (row) => row.identify_name,
        wrap: true,
        minWidth: "200px",
        sortable: true,
        sortField: "identify_name",
      },
      {
        name: "Current City",
        selector: (row) => row.city_name,
        wrap: true,
        minWidth: "200px",
        sortable: true,
        sortField: "city_name",
      },
      {
        name: "About Yourself",
        selector: (row) => row.about_yourself,
        wrap: true,
        minWidth: "200px",
        sortable: true,
        sortField: "about_yourself",
      },
      {
        name: "Qualification",
        selector: (row) => row.highest_education,
        wrap: true,
        minWidth: "200px",
        sortable: true,
        sortField: "highest_education",
      },
      {
        name: "Year of Passing",
        selector: (row) => row.highest_education_passing_year,
        wrap: true,
        minWidth: "200px",
        sortable: true,
        sortField: "highest_education_passing_year",
      },
      {
        name: "College",
        selector: (row) => row.institution_name,
        wrap: true,
        minWidth: "200px",
        sortable: true,
        sortField: "institution_name",
      },
      {
        name: "Apply for internship",
        selector: (row) => row.interested_in_internships,
        wrap: true,
        minWidth: "200px",
        sortable: true,
        sortField: "interested_in_internships",
      },
      {
        name: "Over 16 years of age",
        selector: (row) => row.over_eighteen_years,
        wrap: true,
        minWidth: "200px",
        sortable: true,
        sortField: "over_eighteen_years",
      },
      {
        name: "Internship month(s)",
        selector: (row) => row.internship_available_months,
        wrap: true,
        minWidth: "200px",
        sortable: true,
        sortField: "internship_available_months",
      },
      {
        name: "Aggregate percentage",
        selector: (row) => row.aggregate_percentage,
        wrap: true,
        minWidth: "200px",
        sortable: true,
        sortField: "aggregate_percentage",
      },
      {
        name: "Experience",
        selector: (row) => row.total_experience,
        wrap: true,
        minWidth: "200px",
        sortable: true,
        sortField: "total_experience",
      },
      {
        name: "Current Employer",
        selector: (row) => row.current_previous_company,
        wrap: true,
        minWidth: "200px",
        sortable: true,
        sortField: "current_previous_company",
      },
      {
        name: "Current Role",
        selector: (row) => row.current_profile_role,
        wrap: true,
        minWidth: "200px",
        sortable: true,
        sortField: "current_profile_role",
      },
      {
        name: "Skills",
        selector: (row) => row.skills,
        wrap: true,
        minWidth: "200px",
        sortable: true,
        sortField: "skills",
      },
      {
        name: "CTC",
        selector: (row) => row.current_salary,
        wrap: true,
        minWidth: "200px",
        sortable: true,
        sortField: "current_salary",
      },
    ];
  }, [handleDownload]);

  const [data, setData] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [toggleCleared, setToggleCleared] = useState(false);

  const [columns] = useState(tableClm);

  const [filterText, setFilterText] = useState("");
  const [jobId, setJobId] = useState("");

  const [extraFilter, setExtraFilter] = useState({
    unlock_type: "",
    profile_status: "",
  });

  const [sorting, setSorting] = useState({
    sort_by: "created_at",
    sort_direction: "DESC",
  });

  const [reqParams, setReqParams] = useState({
    page: currentPage,
    limit: perPage,
    sort_by: "created_at",
    sort_direction: "DESC",
    keyword: "",
    unlock_type: "",
    profile_status: "",
    job_id: jobId,
  });

  const [fetch, setFetch] = useState(true);

  const fetchUsers = useCallback(
    (params) => {
      setToggleCleared(!toggleCleared);
      setLoading(true);
      unlockProfileList(params)
        .then((res) => {
          setData(res?.data?.data);
          setTotalRows(res?.data?.totalRecords);
          setLoading(false);
        })
        .catch((err) => {
          swal("Error", err?.message, "error");
          setLoading(false);
        });
    },
    [perPage, filterText, jobId, sorting, extraFilter]
  );

  const fetchJobs = useCallback(() => {
    getPublishedJob()
      .then((res) => {
        setJobs(res?.data?.data);
      })
      .catch((err) => console.log(err));
  }, [setJobs]);

  useEffect(() => {
    if (fetch) {
      fetchUsers(reqParams);
    }
  }, [fetch, reqParams]);

  const handleSearchText = useCallback(
    (text) => {
      setReqParams((state) => {
        return { ...state, keyword: text };
      });
      setFetch(true);
    },
    [setReqParams, setFetch]
  );

  const handlePageChange = useCallback(
    (page) => {
      setCurrentPage(page);
      setReqParams({
        ...reqParams,
        page: page,
      });
      setFetch(true);
    },
    [setReqParams, reqParams, setFetch]
  );

  const handlePerRowsChange = useCallback(
    async (newPerPage, page) => {
      setPerPage(newPerPage);
      setReqParams({
        ...reqParams,
        limit: newPerPage,
      });
      setFetch(true);
    },
    [setReqParams, reqParams, setFetch]
  );

  useEffect(() => {
    fetchJobs();
  }, [fetchJobs]);

  const handleSort = useCallback(
    (column, sortDirection) => {
      if (column?.sortField !== undefined) {
        setReqParams({
          ...reqParams,
          sort_by: column.sortField,
          sort_direction: sortDirection,
        });
        setFetch(true);
      }
    },
    [reqParams, setReqParams, setFetch]
  );

  const candidateStatus = useMemo(() => {
    return [
      { value: 1, title: "CV Shortlisted" },
      { value: 2, title: "Reject" },
      { value: 3, title: "On hold" },
      { value: 4, title: "Offered" },
      { value: 5, title: "DND" },
      { value: 6, title: "Others" },
      { value: 7, title: "In process" },
      { value: 8, title: "Interview Scheduled" },
      { value: 9, title: "Viewed(Action Pending)" },
    ];
  }, []);

  const handleExtraFilter = useCallback(
    (filter) => {
      setReqParams((state) => {
        return { ...state, ...filter };
      });
      setFetch(true);
    },
    [setFetch, setReqParams]
  );

  return (
    <>
      <div className="d-flex align-items-center mb-4 flex-wrap">
        <h4 className="fs-20 font-w600  me-auto"></h4>
        <select
          onChange={(e) => handleExtraFilter({ unlock_type: e.target.value })}
          className="btn btn-success btn-sm me-2"
        >
          <option value="">Select Candidate Type</option>
          <option value="G">Database</option>
          <option value="A">Applicant</option>
        </select>

        <select
          onChange={(e) =>
            handleExtraFilter({ profile_status: e.target.value })
          }
          className="btn btn-dark btn-sm me-2"
        >
          <option value="">Select Candidate Status</option>
          {candidateStatus.map((status, i) => {
            return (
              <option value={status?.value} key={i}>
                {status?.title}
              </option>
            );
          })}
        </select>

        <select
          id="min_experience"
          //onChange={(e) => setJobId(e.target.value)}
          onChange={(e) => handleExtraFilter({ jobId: e.target.value })}
          className="btn btn-warning btn-sm me-2"
          type="text"
          name="min_experience"
        >
          <option value="">Select Job</option>
          {jobs.length > 0 &&
            jobs.map((job, i) => {
              return (
                <option value={job?.id} key={i}>
                  {job?.job_title}
                </option>
              );
            })}
        </select>

        <button
          onClick={() => downloadCSV(data,'unlock-candidate-')}
          className="btn btn-danger btn-sm "
        >
          <i className="fas fa-file-csv me-1"></i>Export
        </button>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="table-responsive">
            <DataTable
              className="table display dataTablesCard job-table table-responsive-xl card-table dataTable no-footer"
              columns={columns}
              data={data}
              progressPending={loading}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              paginationDefaultPage={currentPage}
              onChangeRowsPerPage={handlePerRowsChange}
              paginationRowsPerPageOptions={[10, 20, 30, 50, 100, 200, 500]}
              onChangePage={handlePageChange}
              selectableRows
              persistTableHead
              subHeader
              subHeaderComponent={
                <SearchInputComponent setFilterText={handleSearchText} />
              }
              onSelectedRowsChange={({ selectedRows }) => {
                //console.log(selectedRows)
                //setSelectedRows(selectedRows);
              }}
              clearSelectedRows={toggleCleared}
              onSort={handleSort}
              sortServer
            />
          </div>
        </div>
      </div>
    </>
  );
}
