import React, { useCallback, useState } from "react";
import { Modal } from "react-bootstrap";
import swal from "sweetalert";
import { addNewUser } from "../../api/user";
import ErrorToast from "../../layouts/nav/ErrorToast";

const initialState = {
  username: "",
  password: "",
  repassword: "",
  email: "",
  role_id: "",
  quota: "",
  user_type: "",
  created_by: "",
  published: "1",
};

const NewUser = (props) => {
  const { addCard, setAddCard, menuData, dataFetch } = props;
  const [addFormData, setAddFormData] = useState(initialState);
  const [errorMsg, setErrorMsg] = useState("");

  // Add contact function
  const handleAddFormChange = (event) => {
    event.preventDefault();
    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;
    const newFormData = { ...addFormData };
    newFormData[fieldName] = fieldValue;
    setAddFormData(newFormData);
  };

  //   const validateEmail = (email) => {
  //    return String(email)
  //      .toLowerCase()
  //      .match(
  //        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  //      );
  //  };

  const validateEmail = useCallback((email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  }, []);

  //Add Submit data
  const handleAddFormSubmit = async (event) => {
    event.preventDefault();
    var error = false;
    var errorMsg = "";
    if (addFormData.username === "") {
      error = true;
      errorMsg = "Please fill User Name";
    } else if (addFormData.email === "") {
      error = true;
      errorMsg = "Please fill User Email";
    } else if (!validateEmail(addFormData.email)) {
      error = true;
      errorMsg = "Please enter valid email";
    } else if (addFormData.password === "") {
      error = true;
      errorMsg = "Please fill Password";
    } else if (addFormData.repassword === "") {
      error = true;
      errorMsg = "Please fill Confirm Password";
    } else if (addFormData.password !== addFormData.repassword) {
      error = true;
      errorMsg = "Confirm password is not match with password";
    } else if (addFormData.role_id === "") {
      error = true;
      errorMsg = "Please select Role";
    } else if (addFormData.user_type === "2" && addFormData.quota === "") {
      error = true;
      errorMsg = "Please enter quota";
    } else if (addFormData.user_type === "") {
      error = true;
      errorMsg = "Please select user type";
    }

    if (!error) {
      const newContact = {
        name: addFormData.username,
        email: addFormData.email,
        password: addFormData.password,
        role_id: addFormData.role_id,
        quota: addFormData.quota,
        user_type: addFormData.user_type,
        created_by: 1,
        status: addFormData.published,
      };
      await addNewUser(newContact);
      dataFetch();
      setAddCard(false);
      setAddFormData(initialState);
      swal("Successfully Added User!", "Successfully Added", "success");
    } else {
      setErrorMsg(errorMsg);
      //swal('Oops', errorMsg, "error");
    }
  };

  return (
    <>
      <Modal
        className="modal fade bd-example-modal-lg"
        show={addCard}
        onHide={() => setAddCard(false)}
        centered
        size="lg"
      >
        <div role="document">
          <div className="">
            <form>
              <div className="modal-header">
                <h4 className="modal-title fs-20">Add New User</h4>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setAddCard(false)}
                  data-dismiss="modal"
                >
                  <span></span>
                </button>
              </div>
              <div className="modal-body">
                <i
                  className="flaticon-cancel-12 close"
                  data-dismiss="modal"
                ></i>
                <div className="add-contact-box">
                  <div className="add-contact-content">
                    <ErrorToast setErrorMsg={setErrorMsg} errorMsg={errorMsg} />

                    <div className="form-group mb-6">
                      <div className="row">
                        <div className="form-group mb-6 col-md-12">
                          <label className="text-black font-w500 mt-2">
                            Username <span className="text-danger">*</span>{" "}
                          </label>
                          <div className="contact-name">
                            <input
                              type="text"
                              className="form-control"
                              autoComplete="off"
                              name="username"
                              required="required"
                              onChange={handleAddFormChange}
                              placeholder="Enter User Name"
                            />
                            <span className="validation-text"></span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-group mb-6">
                      <div className="row">
                        <div className="form-group mb-6 col-md-12">
                          <label className="text-black font-w500 mt-2">
                            User Email <span className="text-danger">*</span>
                          </label>
                          <div className="contact-name">
                            <input
                              type="text"
                              className="form-control"
                              autoComplete="off"
                              name="email"
                              required="required"
                              onChange={handleAddFormChange}
                              placeholder="Enter User Email Address"
                            />
                            <span className="validation-text"></span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-group mb-6">
                      <div className="row">
                        <div className="form-group mb-6 col-md-12">
                          <label className="text-black font-w500 mt-2">
                            Password <span className="text-danger">*</span>
                          </label>
                          <div className="contact-name">
                            <input
                              type="password"
                              className="form-control"
                              autoComplete="off"
                              name="password"
                              required="required"
                              onChange={handleAddFormChange}
                              placeholder="Enter Password"
                            />
                            <span className="validation-text"></span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-group mb-6">
                      <div className="row">
                        <div className="form-group mb-6 col-md-12">
                          <label className="text-black font-w500 mt-2">
                            Confirm Password{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <div className="contact-name">
                            <input
                              type="password"
                              className="form-control"
                              autoComplete="off"
                              name="repassword"
                              required="required"
                              onChange={handleAddFormChange}
                              placeholder="Enter Confirm Password"
                            />
                            <span className="validation-text"></span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-group mb-6">
                      <div className="row">
                        <div className="form-group mb-6 col-md-12">
                          <label className="text-black font-w500 mt-2">
                            Select Role <span className="text-danger">*</span>
                          </label>
                          <div className="contact-name">
                            <select
                              className="form-select"
                              id="role_id"
                              name="role_id"
                              onChange={handleAddFormChange}
                            >
                              <option value="">Please select</option>
                              {menuData?.map((item, index) => {
                                return (
                                  <>
                                    <option value={item.id}>
                                      {item.role_name}
                                    </option>
                                  </>
                                );
                              })}
                            </select>

                            <span className="validation-text"></span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-group mb-6">
                      <div className="row">
                        <div className="form-group mb-6 col-md-12">
                          <label className="text-black font-w500 mt-2">
                            User Type <span className="text-danger">*</span>
                          </label>
                          <div className="contact-name">
                            <select
                              className="form-select"
                              id="user_type"
                              name="user_type"
                              onChange={handleAddFormChange}
                            >
                              <option value="">Select User Type</option>
                              <option value="1">Admin</option>
                              <option value="2">Editor</option>
                            </select>
                            <span className="validation-text"></span>
                          </div>
                        </div>
                      </div>
                    </div>

                    {addFormData.user_type === "2" && (
                      <div className="form-group mb-6">
                        <div className="row">
                          <div className="form-group mb-6 col-md-12">
                            <label className="text-black font-w500 mt-2">
                              Unlock Quota{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <div className="contact-name">
                              <input
                                type="number"
                                className="form-control"
                                autoComplete="off"
                                name="quota"
                                required="required"
                                onChange={handleAddFormChange}
                                placeholder="Unlock Quota"
                              />
                              <span className="validation-text"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="form-group mb-3">
                      <label className="text-black font-w500 mt-2">
                        Status
                      </label>
                      <div className="contact-name">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="published"
                          value="1"
                          id="statusActive"
                          checked={true}
                          onChange={handleAddFormChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="statusActive"
                        >
                          Active
                        </label>
                        <input
                          className="form-check-input"
                          type="radio"
                          name="published"
                          value="0"
                          id="statusInactive"
                          onChange={handleAddFormChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="statusInactive"
                        >
                          InActive
                        </label>
                        <span className="validation-text"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="submit"
                  className="btn btn-success"
                  onClick={handleAddFormSubmit}
                >
                  Add
                </button>
                <button
                  type="button"
                  onClick={() => setAddCard(false)}
                  className="btn btn-danger"
                >
                  <i className="flaticon-delete-1"></i> Discard
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default NewUser;
